<template>
  <div class="sidebar-detached sidebar-left sidebar-sticky">
    <div class="sidebar">
      <div
        class="sidebar-shop mt-0"
        :class="{'show': mqShallShowRightSidebar}"
      >
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading mb-1 d-none d-lg-block">
              Order Summary
            </h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <b-card class="">
          <!-- Header -->
          <ul class="pl-0">
            <li class="dropdown-menu-header dropdown-menu-media">
              <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">
                  My Order
                </h4>
                <b-badge
                  pill
                  variant="light-primary"
                >
                  {{ order.order_items.length }} item{{ order.order_items.length === 1 ? '' : 's' }}
                </b-badge>
              </div>
            </li>

            <!-- Cart order -->
            <vue-perfect-scrollbar
              v-if="order.order_items.length > 0"
              :settings="perfectScrollbarSettings"
              class="scrollable-container media-list scroll-area dropdown-cart"
              tagname="li"
            >
              <b-media
                v-for="orderItem in order.order_items"
                :key="keyForItem(orderItem)"
                class="px-0"
              >
                <feather-icon
                  icon="XIcon"
                  class="cart-item-remove cursor-pointer"
                  @click.stop="confirmRemoval(orderItem)"
                />
                <div class="media-heading">
                  <h6 class="cart-item-title">
                    <span class="text-body">
                      {{ orderItem.order_item_name }}
                    </span>
                  </h6>
                </div>

                <div class="cart-item-qty float-left">
                  <b-form-spinbutton
                    :value="orderItem.order_item_quantity"
                    min="1"
                    max="9999"
                    size="sm"
                    deb
                    @change="handleQuantityChange($event, orderItem)"
                  />
                </div>

                <h5 class="cart-item-price">
                  ${{ orderItem.order_item_subtotal }}
                </h5>
              </b-media>
            </vue-perfect-scrollbar>

            <!-- Cart Footer -->
            <li
              v-if="order.order_items.length > 0"
              class="dropdown-menu-footer"
            >
              <div class="d-flex justify-content-between mb-1">
                <h6 class="font-weight-bolder mb-0">
                  Subtotal:
                </h6>
                <h6 class="text-primary font-weight-bolder mb-0">
                  ${{ order.order_subtotal }}
                </h6>
              </div>
              <div
                v-for="(fee, index) in order.order_fees"
                :key="`fee${index}`"
                class="d-flex justify-content-between mb-1"
              >
                <h6 class="font-weight-bolder mb-0">
                  {{ fee.fee.fee_name }}
                </h6>
                <h6 class="text-primary font-weight-bolder mb-0">
                  ${{ fee.order_fee_amount }}
                </h6>
              </div>
              <div
                v-for="(discount, index) in order.order_discounts"
                :key="`discount${index}`"
                class="d-flex justify-content-between mb-1"
              >
                <h6 class="font-weight-bolder mb-0">
                  {{ discount.discount.discount_name }}
                </h6>
                <h6 class="text-primary font-weight-bolder mb-0">
                  -${{ discount.order_discount_amount }}
                </h6>
              </div>
              <b-button
                variant="primary"
                block
                @click="handleClick"
              >
                Continue
              </b-button>
            </li>

            <p
              v-else
              class="m-0 p-1 text-center"
            >
              No items in your order
            </p>
          </ul>
        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowRightSidebar}"
      @click="$emit('update:mq-shall-show-right-sidebar', false)"
    />

    <icon-modal
      ref="confirm"
      icon="XOctagonIcon"
      icon-classes="text-danger"
      @close="temp = null"
    >
      <p class="text-center">
        Are you sure you would like to remove this item from your order?
      </p>
      <template v-slot:footer>
        <b-button @click="$refs.confirm.close()">
          Go Back
        </b-button>
        <spinner-button
          variant="danger"
          :loading="removing"
          @click="removeItem"
        >
          Remove
        </spinner-button>
      </template>
    </icon-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BBadge, BButton, BFormSpinbutton, BMedia,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref } from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import IconModal from '@/layouts/components/IconModal.vue'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import orderStoreService from '@/services/orderStoreService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BButton,
    BFormSpinbutton,
    BMedia,

    VuePerfectScrollbar,
    IconModal,
    SpinnerButton,
  },
  directives: {
    Ripple,
  },
  props: {
    mqShallShowRightSidebar: {
      type: Boolean,
      required: true,
    },
    orderId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    perfectScrollbarSettings: {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    },
  }),
  setup(props, { emit }) {
    const ORDER_SERVICE_KEY = 'app-order'
    if (!store.hasModule(ORDER_SERVICE_KEY)) store.registerModule(ORDER_SERVICE_KEY, orderStoreService)

    const newOrder = {
      order_items: [],
      order_subtotal: 0,
      order_total: 0,
      order_tax: 0,
    }
    const toast = useToast()
    const order = ref(JSON.parse(JSON.stringify(newOrder)))
    const temp = ref(null)
    const confirm = ref(null)

    const removing = ref(false)

    const keyForItem = item => ('item_id' in item ? item.item_id : item.item_group_id)

    const fetch = () => {
      store.dispatch('app-order/fetchOrderSummary', props)
        .then(response => {
          order.value = response.data
          store.commit('app-order/updateOrderSummary', response.data)
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    fetch()

    const handleClick = () => {
      emit('next-step')
    }

    const confirmRemoval = orderItem => {
      temp.value = orderItem
      confirm.value.open()
    }

    const removeItem = () => {
      removing.value = true
      const action = 'item_id' in temp.value ? 'app-order/removeItemFromOrder' : 'app-order/removeItemGroupFromOrder'
      const id = 'item_id' in temp.value ? temp.value.order_item_id : temp.value.order_item_group_id
      const payload = {
        orderId: props.orderId,
        id,
      }
      store.dispatch(action, payload)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully removed the item from your order',
              variant: 'success',
              icon: 'CheckSquareIcon',
            },
          })
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong when removing this item from your order please try again later',
              variant: 'danger',
              icon: 'AlertTriangleIcon',
            },
          })
        }).finally(() => {
          fetch()
          removing.value = false
          confirm.value.close()
          emit('refresh')
        })
    }

    const timer = ref(null)

    const handleQuantityChange = (quantity, item) => {
      clearTimeout(timer.value)

      const { orderId } = props
      timer.value = setTimeout(() => {
        const action = 'item_id' in item ? 'app-order/updateItemInOrder' : 'app-order/updateItemGroupInOrder'
        const id = 'item_id' in item ? item.item_id : item.item_group_id
        store.dispatch(action, { orderId, id, quantity })
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Successfully updated the quantity of your item',
                variant: 'success',
                icon: 'CheckSquareIcon',
              },
            })
          }).catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong trying to update this items quantity please try again later',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            fetch()
            emit('refresh')
          })
      }, 500)
    }

    return {
      fetch,
      order,
      handleClick,
      temp,
      removing,
      confirmRemoval,
      confirm,
      removeItem,
      handleQuantityChange,
      keyForItem,
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'bootstrap-extended/include';

.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}

.dropdown-cart {
  .media {
    position: relative;
    img {
      background: $body-bg;
    }
    &:hover {
      .cart-item-remove {
        visibility: visible;
      }
    }
  }
  .media-heading {
    width: 8rem;
    h6.cart-item-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .cart-item-by {
      color: $text-muted;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .media-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cart-item-price {
    margin-bottom: 0;
    width: 5rem;
    white-space: nowrap;
    font-size: 1.5vmax;
    text-align: right;
  }

  @include media-breakpoint-up(md) {
    .cart-item-price {
      font-size: 1.5vmin;
    }
  }

  .cart-item-remove {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 14px;
    height: 14px;
    cursor: pointer;
    @include media-breakpoint-up(md) {
      visibility: hidden;
    }
  }
}

@include media-breakpoint-up(lg) {
  .sidebar-sticky {
    position: sticky;
    top: 6.5rem;
  }
}

.media-list,
.dropdown-menu-header,
.dropdown-menu-footer {
  list-style: none;
}
</style>
