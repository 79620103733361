<template>
  <form-wizard
    color="#104f9d"
    :title="null"
    :subtitle="null"
    finish-button-text="Submit"
    back-button-text="Previous"
    class="steps-transparent mb-3"
    @on-complete="submit"
  >

    <!--  Material Tab  -->
    <tab-content
      title="Fence Material"
      :before-change="() => $refs.material.validate().then(valid => valid)"
    >
      <validation-observer
        ref="material"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Materials
            </h5>
          </b-col>

          <b-col
            v-for="(option, i) in materialOptions"
            :key="i"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              :name="option.optionText.toLowerCase()"
              rules="required"
            >
              <b-form-group
                :label="option.optionText"
              >
                <v-select
                  v-model="order.options[option.optionText]"
                  :options="Object.keys(option.options)"
                  :class="getValidationStateNonBootstrap(validationContext)"
                  class="form-control p-0 border-control"
                  @input="val => $nextTick(() => { goToNextLevel(option.options[val], i) })"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>

    <!-- Stretches Tab -->
    <tab-content
      title="Stretches"
      :before-change="() => $refs.stretch.validate().then(valid => valid)"
    >
      <div class="d-flex justify-content-center mb-2">
        <b-card-img
          src="@/assets/images/icons/trufence_diagram_step1.svg"
        />
      </div>
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <h5 class="mb-0">
            Stretches
          </h5>
          <small class="text-muted">
            Enter the footage for each stretch
          </small>
        </b-col>
        <b-col cols="12">
          <validation-observer
            ref="stretch"
          >

            <b-row>
              <b-col
                cols="12"
                lg="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="panel size"
                  rules="required"
                >
                  <b-form-group label="Panel Size">
                    <v-select
                      v-model="panelSize"
                      :options="panelSizes"
                      :class="getValidationStateNonBootstrap(validationContext)"
                      class="form-control p-0 border-control"
                    />

                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <validation-provider
              v-if="panelSize != null"
              #default="validationContext"
              name="stretches"
              rules="required|min:1"
            >
              <b-form-group label="Stretches">
                <array-split-input
                  v-model="order.data.stretches[panelSize]"
                  label="Stretch"
                  :use-alphabet="true"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </validation-observer>
        </b-col>
      </b-row>
    </tab-content>

    <!-- Corner Posts Tab -->
    <tab-content
      title="Posts"
      :before-change="() => $refs.posts.validate(valid => valid)"
    >
      <div class="d-flex justify-content-center mb-2">
        <b-card-img
          src="@/assets/images/icons/trufence_diagram_step2.svg"
        />
      </div>
      <validation-observer
        ref="posts"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Posts
            </h5>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              rules="required|min_value:0"
              name="end posts"
            >
              <b-form-group
                label="Corner Posts"
              >
                <b-form-input
                  v-model="order.data.fenceCornerPosts"
                  type="number"
                  min="0"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="gate end posts"
              rules="required|min_value:0"
            >
              <b-form-group
                label="Gate End Posts"
              >
                <b-form-input
                  v-model="order.data.gateEndPosts"
                  type="number"
                  min="0"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>

    <!-- End/Blank Posts Tab -->
    <tab-content
      title="End Posts"
      :before-change="() => $refs.end.validate().then(valid => valid)"
    >
      <div class="d-flex justify-content-center mb-2">
        <b-card-img
          src="@/assets/images/icons/trufence_diagram_step3.svg"
        />
      </div>
      <validation-observer
        ref="end"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              End Posts
            </h5>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="fence end posts"
              rules="required|min_value:0"
            >
              <b-form-group
                label="Fence End Posts (One Panel Attached)"
              >
                <b-form-input
                  v-model="order.data.fenceEndPosts"
                  type="number"
                  min="0"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="gate blank posts"
              rules="required|min_value:0"
            >
              <b-form-group
                label="Gate Blank Posts (No Panels Attached)"
              >
                <b-form-input
                  v-model="order.data.gateBlankPosts"
                  type="number"
                  min="0"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>

    <!-- Gates Tab -->
    <tab-content
      title="Gates"
      :before-change="() => $refs.gates.validate().then(valid => valid)"
    >
      <div class="d-flex justify-content-center mb-2">
        <b-card-img
          src="@/assets/images/icons/trufence_diagram_step4.svg"
        />
      </div>
      <validation-observer
        ref="gates"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Gates
            </h5>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Walk Gates"
              label-size="lg"
            >
              <b-button
                variant="primary"
                class="mb-2"
                @click="walkGates.push('')"
              >
                Add Walk Gate
              </b-button>
              <validation-provider
                v-for="(walkGate, i) in walkGates"
                #default="validationContext"
                :key="i"
                :name="`walk gate ${i + 1}`"
                rules="required"
              >
                <b-form-group
                  :label="`Walk Gate ${i + 1}`"
                >
                  <b-input-group>
                    <v-select
                      v-model="walkGates[i]"
                      :options="walkGateOptions"
                      class="form-control p-0 border-control"
                      :class="getValidationStateNonBootstrap(validationContext)"
                    />
                    <b-input-group-append>
                      <b-button
                        class="py-0 px-1"
                        variant="danger"
                        @click="walkGates.splice(i, 1)"
                      >
                        <feather-icon
                          icon="XSquareIcon"
                          size="18"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Drive Gates"
              label-size="lg"
            >
              <b-button
                variant="primary"
                class="mb-2"
                @click="driveGates.push('')"
              >
                Add Drive Gate
              </b-button>
              <validation-provider
                v-for="(driveGate, i) in driveGates"
                #default="validationContext"
                :key="i"
                :name="`drive gate ${i + 1}`"
                rules="required"
              >
                <b-form-group
                  :label="`Drive Gate ${i + 1}`"
                >
                  <b-input-group>
                    <v-select
                      v-model="driveGates[i]"
                      :options="driveGateOptions"
                      :class="getValidationStateNonBootstrap(validationContext)"
                      class="form-control p-0 border-control"
                    />

                    <b-input-group-append>
                      <b-button
                        class="py-0 px-1"
                        variant="danger"
                        @click="driveGates.splice(i, 1)"
                      >
                        <feather-icon
                          icon="XSquareIcon"
                          size="18"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BCardImg,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import ArraySplitInput from '@/layouts/components/ArraySplitInput.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { min, required } from '@core/utils/validations/validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  ref, computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from '@/store'
import itemStoreService from '@/services/itemStoreService'
import orderStoreService from '@/services/orderStoreService'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    BCardImg,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    ValidationObserver,
    ValidationProvider,

    ArraySplitInput,
  },
  data() {
    return {
      min,
      required,
    }
  },
  setup(props, { emit }) {
    // Store
    const ITEM_STORE_KEY = 'app-item'
    const ORDER_STORE_KEY = 'app-order'

    if (!store.hasModule(ITEM_STORE_KEY)) store.registerModule((ITEM_STORE_KEY, itemStoreService))

    // Variables
    const toast = useToast()
    const saving = ref(false)
    const blankOrder = {
      options: {},
      data: {
        stretches: {},
        fenceCornerPosts: null,
        fenceEndPosts: null,
        gateEndPosts: null,
        gateBlankPosts: null,
        walkGates: {},
        driveGates: {},
      },
    }
    const order = ref(JSON.parse(JSON.stringify(blankOrder)))
    const orderId = router.currentRoute.params.order_id

    const materialOptions = ref([])
    const walkGates = ref([])
    const driveGates = ref([])

    // Computed
    const hasChanges = computed(() => Object.keys(order.value.options).length > 0)
    const panelSizes = computed(() => {
      // Check the last entry in material options for stretches
      if (materialOptions.value.length > 0) {
        const lastEntry = materialOptions.value[materialOptions.value.length - 1]
        // Get the selected key from our order object
        const selectedKey = order.value.options[lastEntry.optionText]
        // Check if that key contains the stretches key
        if (selectedKey && 'stretches' in lastEntry.options[selectedKey]) {
          return Object.keys(lastEntry.options[selectedKey].stretches)
        }
      }
      return []
    })
    const panelSize = computed({
      get() {
        return Object.keys(order.value.data.stretches)[0] ?? null
      },
      set(selected) {
        // Reset the current selected if any
        order.value.data.stretches = {}
        if (selected != null) {
          order.value.data.stretches[selected] = []
        }
      },
    })
    const walkGateOptions = computed(() => {
      // Check the last entry in material options for stretches
      if (materialOptions.value.length > 0) {
        const lastEntry = materialOptions.value[materialOptions.value.length - 1]
        // Get the selected key from our order object
        const selectedKey = order.value.options[lastEntry.optionText]
        // Check if that key contains the stretches key
        if (selectedKey && 'walkGates' in lastEntry.options[selectedKey]) {
          return Object.keys(lastEntry.options[selectedKey].walkGates)
        }
      }
      return []
    })
    const driveGateOptions = computed(() => {
      // Check the last entry in material options for stretches
      if (materialOptions.value.length > 0) {
        const lastEntry = materialOptions.value[materialOptions.value.length - 1]
        // Get the selected key from our order object
        const selectedKey = order.value.options[lastEntry.optionText]
        // Check if that key contains the stretches key
        if (selectedKey && 'driveGates' in lastEntry.options[selectedKey]) {
          return Object.keys(lastEntry.options[selectedKey].driveGates)
        }
      }
      return []
    })

    // Functions
    const goToNextLevel = (selected, index) => {
      if (selected && 'options' in selected) {
        if (selected.optionText in order.value.options) {
          // Remove all options below this entry
          materialOptions.value.splice(index + 1)
          // Reset the next selection
          order.value.options[selected.optionText] = null
        }

        materialOptions.value.push(selected)
        // add the key to our options
        order.value.options[selected.optionText] = null
      } else {
        materialOptions.value.splice(index + 1)
      }
    }
    const fetchMaterials = () => {
      store.dispatch(`${ITEM_STORE_KEY}/fetchItemMaterials`)
        .then(response => {
          materialOptions.value.push(response.data.options)
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong fetching fence materials please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const submit = () => {
      saving.value = true
      // we need to build our gate data
      walkGateOptions.value.forEach(wGate => {
        order.value.data.walkGates[wGate] = walkGates.value.filter(wg => wg === wGate).length
      })
      driveGateOptions.value.forEach(dGate => {
        order.value.data.driveGates[dGate] = driveGates.value.filter(dg => dg === dGate).length
      })
      // For some reason the service keeps getting unregistered up to this point
      // So I added this here in case it wants to be wack
      if (!store.hasModule(ORDER_STORE_KEY)) store.registerModule(ORDER_STORE_KEY, orderStoreService)
      store.dispatch(`${ORDER_STORE_KEY}/submitBuilder`, { orderId, submissionDetails: order.value })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully submitted your order',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          emit('submit')
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong trying to submit your order please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          saving.value = false
        })
    }

    // Validation
    const {
      getValidationState, getValidationStateNonBootstrap,
    } = formValidation()

    // Initial
    fetchMaterials()

    return {
      order,
      hasChanges,
      materialOptions,
      panelSizes,
      panelSize,
      walkGates,
      walkGateOptions,
      driveGates,
      driveGateOptions,

      submit,
      goToNextLevel,

      getValidationState,
      getValidationStateNonBootstrap,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/assets/fonts/feather/iconfont.css';
@import '~@core/scss/vue/libs/vue-wizard.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
@import "~@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped lang="scss">
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.border-control::v-deep .vs__dropdown-toggle {
  border: 0;
  border-radius: 0;
}

img {
  height: auto;
  width: 75%;

  @include media-breakpoint-up(md) {
    width: 25%;
  }
}
</style>
