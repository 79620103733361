<template>
  <b-row>
    <b-col
      v-for="(val, index) in arrayValue"
      :key="index"
      cols="12"
      lg="3"
      class="mb-1"
    >
      <validation-provider
        #default="validationContext"
        :name="titleForIndex(index)"
        rules="required"
      >
        <b-form-group
          :label="titleForIndex(index)"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              v-model="arrayValue[index]"
              :state="getValidationState(validationContext)"
              :type="inputType"
            />
            <template v-slot:append>
              <b-button
                variant="danger"
                class="py-0 px-1"
                @click="removeEntry(index)"
              >
                <feather-icon
                  icon="XSquareIcon"
                  size="18"
                />
              </b-button>
            </template>
          </b-input-group>
        </b-form-group>
      </validation-provider>
    </b-col>
    <b-col
      cols="12"
      lg="3"
      class="mb-1"
    >
      <div class="d-flex h-100">
        <b-button
          variant="primary"
          block
          class="mt-auto"
          @click="addEntry"
        >
          Add
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { computed } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    ValidationProvider,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    inputType: {
      type: String,
      default: 'number',
    },
    label: {
      type: String,
      default: 'Value',
    },
    useAlphabet: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    // Variables
    const letters = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ]

    // Computed
    const arrayValue = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    // Validation
    const {
      getValidationState,
    } = formValidation()

    // Functions
    const addEntry = () => {
      arrayValue.value.push('')
    }
    const removeEntry = index => {
      arrayValue.value.splice(index, 1)
    }
    const titleForIndex = index => {
      if (props.useAlphabet) {
        let addon

        if (index > 25) {
          const i = (index % 25) - 1
          addon = letters[i].repeat(Math.floor(index / 25) + 1)
        } else {
          addon = letters[index]
        }

        return `${props.label} ${addon}`
      }

      return `${props.label} ${index + 1}`
    }

    return {
      arrayValue,

      getValidationState,

      addEntry,
      removeEntry,
      titleForIndex,
    }
  },
}
</script>
