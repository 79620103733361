<template>
  <div
    style="height: 100%"
    class="content-wrapper clearfix"
  >
    <div class="content-detached content-left">
      <div class="content-wrapper">
        <div class="content-body">
          <!-- ECommerce Header -->
          <section id="ecommerce-header">
            <div class="row">
              <div class="col-sm-12">
                <div class="ecommerce-header-items">
                  <div class="result-toggler">
                    <feather-icon
                      icon="MenuIcon"
                      class="d-block d-lg-none"
                      size="21"
                      @click="mqShallShowLeftSidebar = true"
                    />
                    <div class="search-results">
                      {{ totalItems }} results found
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- Overlay -->
          <div class="body-content-overlay" />

          <!-- Searchbar -->
          <div class="mt-1">
            <b-row>
              <b-col
                cols="12"
                lg="6"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="filters.q"
                    placeholder="Search Product"
                    debounce="500"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="SearchIcon"
                      class="text-muted"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col
                cols="12"
                lg="6"
                class="mt-2 mt-lg-0"
              >
                <category-picker
                  v-model="filters.categories"
                  multiple
                  class="h-100"
                />
              </b-col>
            </b-row>
          </div>

          <!-- products -->
          <section class="grid-view product-checkout">
            <b-card
              v-for="(item, i) in items"
              :key="idForItem(item)"
              class="ecommerce-card"
              no-body
            >
              <div @click.prevent="openItemDetail(item)">
                <div class="item-img d-flex justify-content-center">
                  <b-img
                    :alt="idForItem(item)"
                    fluid
                    class="card-img-top p-1 h-100"
                    :class="{ 'logo-img': (item.image_url == null || item.image_url.length === 0) }"
                    :src="imageForItem(item)"
                  />
                </div>

                <!-- Product Details -->
                <b-card-body>
                  <div class="item-wrapper">
                    <div>
                      <h6 class="item-price">
                        ${{ item.price }}
                      </h6>
                    </div>
                  </div>
                  <h3 class="item-name">
                    <span
                      class="text-body"
                    >
                      {{ item.display_name != null && item.display_name.length > 0 ?item.display_name : item.name }}
                    </span>
                  </h3>
                  <b-card-text class="item-description font-medium-4">
                    {{ item.details }}
                  </b-card-text>
                </b-card-body>
              </div>

              <!-- Product Actions -->
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex justify-content-center align-items-center mb-1"
                >
                  <validation-provider
                    ref="quantities"
                    #default="validationContext"
                    :name="`quantity-${idForItem(item)}`"
                    rules="required|min_value:1"
                  >
                    <b-form-group class="mb-0 mt-1">
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-form-input
                          ref="quantityInputs"
                          :value="(itemQuantities[idForItem(item)] ? itemQuantities[idForItem(item)] : 1)"
                          number
                          min="1"
                          type="number"
                          class="text-center"
                          :state="getValidationState(validationContext)"
                          data-vv-validate-on=""
                          debounce="1000"
                          @input="updateItemQuantity(item, i, ...arguments)"
                        />
                        <template
                          v-if="isItemInOrder(item)"
                          #append
                        >
                          <b-input-group-text
                            class="h-100"
                          >
                            <b-spinner
                              ref="quantitySpinners"
                              class="d-none"
                              small
                            />
                          </b-input-group-text>
                        </template>
                      </b-input-group>

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <div class="item-options text-center">
                <div class="item-wrapper">
                  <div class="item-cost">
                    <h4 class="item-price h3 font-weight-bolder">
                      ${{ item.price }}
                    </h4>
                  </div>
                </div>
                <b-button
                  v-if="isItemInOrder(item)"
                  variant="danger"
                  class="btn-wishlist"
                  @click="confirmRemoval(item)"
                >
                  Remove From Order
                </b-button>
                <spinner-button
                  v-else
                  ref="buttons"
                  variant="primary"
                  class="btn-cart"
                  @click="handleCartActionClick(item, i)"
                >
                  <span>Add To Order</span>
                </spinner-button>
              </div>
            </b-card>

            <b-modal
              v-if="selectedItem != null"
              id="item-detail-modal"
              title="Item Detail"
              ok-only
              ok-title="Close"
            >
              <b-card-body>
                <b-row>
                  <b-col md="3">
                    <b-img
                      fluid
                      class="card-img-top p-1 w-100"
                      :class="{ 'logo-img': (selectedItem.image_url == null || selectedItem.image_url.length === 0) }"
                      :src="imageForItem(selectedItem)"
                    />
                  </b-col>
                  <b-col md="9">
                    <h3>{{ selectedItem.display_name }}</h3>
                    <h6 class="item-price">
                      ${{ selectedItem.price }}
                    </h6>
                    <p>{{ selectedItem.details }}</p>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-modal>
          </section>

          <!-- Pagination -->
          <section>
            <b-row>
              <b-col cols="12">
                <b-pagination
                  v-model="filters.page"
                  :total-rows="totalItems"
                  :per-page="filters.perPage"
                  first-number
                  align="center"
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </section>

          <icon-modal
            ref="confirm"
            icon="XOctagonIcon"
            icon-classes="text-danger"
            @close="temp = null"
          >
            <p class="text-center">
              Are you sure you would like to remove this item from your order?
            </p>
            <template v-slot:footer>
              <b-button
                variant="default"
                @click="$refs.confirm.close()"
              >
                Go Back
              </b-button>
              <spinner-button
                :loading="removing"
                variant="danger"
                @click="removeItem"
              >
                Remove
              </spinner-button>
            </template>
          </icon-modal>
          <scroll-to-top />
        </div>
      </div>
    </div>

    <portal-target
      name="sidebar"
      slim
    />

    <!-- Sidebar -->
    <portal to="sidebar">
      <order-summary-side-panel
        ref="summary"
        :order-id="+orderId"
        :mq-shall-show-right-sidebar.sync="mqShallShowLeftSidebar"
        @next-step="$emit('next-step')"
        @refresh="fetchOrderItems"
      />
    </portal>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupText,
  BFormInput,
  BCard,
  BCardBody,
  BImg,
  BCardText,
  BPagination,
  BButton,
  BFormGroup,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import {
  watch, ref, nextTick,
} from '@vue/composition-api'
import { required, minValue } from '@core/utils/validations/validations'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { $themeConfig } from '@themeConfig'
import orderStoreService from '@/services/orderStoreService'
import store from '@/store'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import IconModal from '@/layouts/components/IconModal.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import CategoryPicker from '@/views/categories/CategoryPicker.vue'
import OrderSummarySidePanel from './OrderSummarySidePanel.vue'
import {
  useShopFiltersSortingAndPagination,
  useShopRemoteData,
} from './useShop'

export default {
  components: {
    // BSV
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BFormInput,
    BCard,
    BCardBody,
    BImg,
    BCardText,
    BPagination,
    BButton,
    BFormGroup,
    BFormInvalidFeedback,
    BSpinner,
    CategoryPicker,

    ValidationProvider,

    IconModal,

    SpinnerButton,
    ScrollToTop,

    // SFC
    OrderSummarySidePanel,
  },
  setup() {
    const TYPE_ITEM = 'item'
    const TYPE_ITEM_GROUP = 'group'

    const quantities = ref(null)
    const buttons = ref(null)
    const ORDER_STORE_MODULE_NAME = 'app-order'
    const confirm = ref(null)
    const temp = ref(null)
    const itemQuantities = ref([])
    const quantityInputs = ref(null)
    const quantitySpinners = ref(null)
    const timer = ref(null)
    const summary = ref(null)
    const selectedItem = ref(null)

    const toast = useToast()

    const orderId = router.currentRoute.params.order_id
    const orderItems = ref([])
    const removing = ref(false)

    if (!store.hasModule(ORDER_STORE_MODULE_NAME)) store.registerModule(ORDER_STORE_MODULE_NAME, orderStoreService)

    const { filters } = useShopFiltersSortingAndPagination()
    // Pagination count <= required by pagination component
    const totalItems = ref(null)

    const idForItem = item => `${item.name}-${item.id}`

    const isItemInOrder = item => {
      if (item.type === TYPE_ITEM) {
        return orderItems.value.find(oi => +oi.item_id === +item.id) !== undefined
      }
      if (item.type === TYPE_ITEM_GROUP) {
        return orderItems.value.find(oi => +oi.item_group_id === +item.id) !== undefined
      }

      return false
    }

    const fetchOrderItems = () => {
      store.dispatch('app-order/checkOrderItems', { orderId })
        .then(response => {
          orderItems.value = response.data
          itemQuantities.value = []
          orderItems.value.forEach(item => {
            const key = 'item_group' in item ? `${item.item_group.item_group_name}-${item.item_group_id}` : `${item.item.item_name}-${item.item_id}`
            const value = 'item_group' in item ? item.order_item_group_quantity : item.order_item_quantity
            itemQuantities.value[key] = value
          })
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          router.push({ name: 'orders' })
        })
    }

    const refresh = () => {
      summary.value.fetch()
      fetchOrderItems()
    }

    const handleCartActionClick = (item, index, callback = null) => {
      const quantityValidation = quantities.value[index]

      quantityValidation.validate().then(response => {
        if (response.valid) {
          let button
          if (callback == null) {
            button = buttons.value[index]
            if (button === undefined) {
              [button] = buttons.value.slice(-1)
            }
            button.start()
          }
          const action = item.type === TYPE_ITEM ? 'app-order/addItemToOrder' : 'app-order/addItemGroupToOrder'

          store.dispatch(action, { orderId, id: item.id, quantity: itemQuantities.value[idForItem(item)] })
            .then(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: `Successfully ${isItemInOrder(item) ? 'updated' : 'added'} the item ${isItemInOrder(item) ? 'in' : 'to'} your order`,
                  variant: 'success',
                  icon: 'CheckSquareIcon',
                },
              })

              summary.value.fetch()
            }).catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong trying to add this item to your order please try again later',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }).finally(() => {
              if (callback == null) {
                button.stop()
              } else {
                callback()
              }
              fetchOrderItems()
              quantityValidation.reset()
            })
        }
      })
    }

    const updateItemQuantity = (item, index, val) => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        itemQuantities.value[idForItem(item)] = val
        if (isItemInOrder(item)) {
          const quantityInput = quantityInputs.value[index]
          const spinner = quantitySpinners.value[orderItems.value.indexOf(item)] ?? quantitySpinners.value[quantitySpinners.value.length - 1]
          spinner.classList.remove('d-none')

          nextTick(() => {
            handleCartActionClick(item, index, () => {
              quantityInput.disabled = false
              spinner.classList.add('d-none')
            })
          })
        }
      }, 500)
    }

    const confirmRemoval = item => {
      temp.value = item
      confirm.value.open()
    }

    const removeItem = () => {
      removing.value = true
      const action = temp.value.type === TYPE_ITEM ? 'app-order/removeItemFromOrder' : 'app-order/removeItemGroupFromOrder'
      const orderItem = orderItems.value.find(oi => {
        if (temp.value.type === TYPE_ITEM) {
          return +temp.value.id === +oi.item_id
        }

        return +temp.value.id === +oi.item_group_id
      })
      const id = 'order_item_id' in orderItem ? orderItem.order_item_id : orderItem.order_item_group_id
      store.dispatch(action, { orderId, id })
        .then(() => {
          itemQuantities.value[idForItem(temp.value)] = 1
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully removed the item from your order',
              variant: 'success',
              icon: 'CheckSquareIcon',
            },
          })
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong when removing this item from your order please try again later',
              variant: 'danger',
              icon: 'AlertTriangleIcon',
            },
          })
        }).finally(() => {
          refresh()
          removing.value = false
          confirm.value.close()
        })
    }

    const { items, fetchItems } = useShopRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const fetchShopItems = () => {
      items.value = []
      fetchItems({
        q: filters.value.q,
        page: filters.value.page,
        perPage: filters.value.perPage,
        categories: filters.value.categories.join(','),
      }).then(response => {
        items.value = response.data.data
        totalItems.value = response.data.total
      })
    }

    const imageForItem = item => item.image_url ?? $themeConfig.app.appLogoWithName

    const {
      getValidationState,
    } = formValidation(null)

    fetchOrderItems()
    fetchShopItems()

    watch(
      [filters],
      () => {
        fetchShopItems()
      },
      {
        deep: true,
      },
    )

    return {
      filters,

      idForItem,
      totalItems,
      handleCartActionClick,
      updateItemQuantity,

      removeItem,
      confirmRemoval,
      confirm,
      removing,
      temp,

      imageForItem,
      isItemInOrder,

      // useShopRemoteData
      items,
      itemQuantities,
      quantityInputs,
      quantitySpinners,

      orderItems,

      // showFilter
      mqShallShowLeftSidebar,

      quantities,
      buttons,

      getValidationState,

      orderId,
      summary,
      selectedItem,

      fetchOrderItems,
      refresh,
    }
  },
  data: () => ({
    required,
    minValue,
  }),
  methods: {
    openItemDetail(item) {
      this.selectedItem = item
      this.$nextTick(() => {
        this.$bvModal.show('item-detail-modal')
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";

.category-picker {
  box-shadow: 0 2px 8px 0 rgba($black, 0.14);
  border-radius: 0.3rem;
  height: $search-bar-height;
  font-size: 0.95rem;
  padding-left: 1.25rem;

  &::placeholder {
    font-size: 0.95rem;
  }
}
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.logo-img {
  width: 15rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
</style>
