<template>
  <form-wizard
    ref="refFormWizard"
    color="#104f9d"
    :title="null"
    :subtitle="null"
    finish-button-text="Submit"
    back-button-text="Previous"
    hide-buttons
    class="checkout-form-wizard steps-transparent"
    @on-change="refreshTab"
  >
    <b-alert
      v-if="fromBuilder"
      show
      variant="warning"
    >
      <div class="alert-heading">
        <h4
          v-if="builderDisclaimerTitle != null"
        >
          {{ builderDisclaimerTitle }}
        </h4>
        <p
          v-if="builderDisclaimerMessage != null"
          class="mb-0"
        >
          {{ builderDisclaimerMessage }}
        </p>
      </div>
    </b-alert>
    <!-- item selection tab -->
    <tab-content
      title="Order"
      icon="feather icon-clipboard"
      :before-change="validateNextStep"
    >
      <shop
        ref="shop"
        @next-step="formWizardNextStep()"
      />
    </tab-content>

    <!--  order summary tab  -->
    <tab-content
      ref="tab-summary"
      title="Summary"
      icon="feather icon-info"
      :before-change="validateNextStep"
    >
      <order-summary
        ref="summary"
        @next-step="formWizardNextStep()"
      />
    </tab-content>

    <!-- locations tab -->
    <tab-content
      title="Review"
      icon="feather icon-check-circle"
      :before-change="validateNextStep"
    >
      <order-review
        ref="review"
      />
    </tab-content>
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ref, computed } from '@vue/composition-api'
import OrderSummary from '@/views/shop/OrderSummary.vue'
import OrderReview from '@/views/shop/OrderReview.vue'
import store from '@/store'
import OrderStoreService from '@/services/orderStoreService'
import {
  BAlert,
} from 'bootstrap-vue'
import Shop from './Shop.vue'

export default {
  components: {
    // 3rd Party
    FormWizard,
    TabContent,
    BAlert,

    Shop,
    OrderSummary,
    OrderReview,
  },
  setup() {
    const ORDER_STORE_MODULE_NAME = 'app-order'
    if (!store.hasModule(ORDER_STORE_MODULE_NAME)) {
      store.registerModule(ORDER_STORE_MODULE_NAME, OrderStoreService)
    }

    const refFormWizard = ref(null)

    const shop = ref(null)

    const formWizardNextStep = () => {
      if (shop.value.orderItems.length > 0) {
        refFormWizard.value.nextTab()
      }
    }
    const validateNextStep = () => shop.value.orderItems.length > 0
    const refreshTab = (prev, next) => {
      const tabReference = refFormWizard.value.tabs[next]?.$children[0]
      if (tabReference !== undefined && typeof tabReference.refresh === 'function' && store.hasModule('app-order')) {
        tabReference.refresh()
      }
    }

    const builderDisclaimerTitle = computed(() => store.getters['app-order/builderDisclaimerTitle'])

    const builderDisclaimerMessage = computed(() => store.getters['app-order/builderDisclaimerMessage'])

    const fromBuilder = computed(() => {
      if (store.getters['app-order/fromBuilder']) {
        return builderDisclaimerTitle.value != null || builderDisclaimerMessage.value != null
      }

      return false
    })

    return {
      refFormWizard,
      formWizardNextStep,
      refreshTab,
      shop,
      validateNextStep,
      fromBuilder,
      builderDisclaimerTitle,
      builderDisclaimerMessage,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
@import '~@core/assets/fonts/feather/iconfont.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
