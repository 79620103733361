<template>
  <div class="position-relative">
    <b-button
      class="view-button"
      variant="outline-primary"
      @click="handleSwitch()"
    >
      {{ viewType === VIEW_TYPE_BUILDER ? 'Store' : 'Builder' }}
    </b-button>
    <shop-wizard
      v-if="viewType === VIEW_TYPE_STORE"
    />
    <order-builder-wizard
      v-else-if="viewType === VIEW_TYPE_BUILDER"
      ref="builder"
      @submit="changeView()"
    />

    <icon-modal
      ref="confirmSwitch"
      icon="AlertTriangleIcon"
      icon-classes="text-warning"
    >
      <p class="text-center">
        You have unsaved changes are you sure you would like to leave?
      </p>
      <template v-slot:footer>
        <b-button @click="$refs.confirmSwitch.close()">
          Go Back
        </b-button>

        <b-button
          variant="primary"
          @click="changeView"
        >
          Switch
        </b-button>
      </template>
    </icon-modal>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import ShopWizard from '@/views/shop/ShopWizard.vue'
import OrderBuilderWizard from '@/views/shop/OrderBuilderWizard.vue'
import IconModal from '@/layouts/components/IconModal.vue'
import { BButton } from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    ShopWizard,
    OrderBuilderWizard,
    IconModal,

    BButton,
  },
  setup() {
  // Store
    const ITEM_STORE_KEY = 'app-item'
    const ORDER_STORE_KEY = 'app-order'

    onUnmounted(() => {
      if (store.hasModule(ITEM_STORE_KEY)) store.unregisterModule(ITEM_STORE_KEY)
      if (store.hasModule(ORDER_STORE_KEY)) store.unregisterModule(ORDER_STORE_KEY)
    })
    // Constants
    const VIEW_TYPE_STORE = 'store'
    const VIEW_TYPE_BUILDER = 'builder'

    // Variables
    const viewType = ref(VIEW_TYPE_STORE)
    const confirmSwitch = ref(null)
    const builder = ref(null)

    // Functions
    const changeView = () => {
      viewType.value = viewType.value === VIEW_TYPE_BUILDER ? VIEW_TYPE_STORE : VIEW_TYPE_BUILDER
      confirmSwitch.value.close()
    }
    const handleSwitch = () => {
      if (viewType.value === VIEW_TYPE_BUILDER && builder.value.hasChanges) {
        confirmSwitch.value.open()
      } else {
        changeView()
      }
    }

    return {
      VIEW_TYPE_BUILDER,
      VIEW_TYPE_STORE,

      viewType,
      confirmSwitch,
      builder,

      changeView,
      handleSwitch,
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'bootstrap-extended/include';

.view-button {
  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 10;
  }
}
</style>
