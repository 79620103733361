<template>
  <div class="list-view product-checkout mt-0">

    <!-- Products List -->
    <div>
      <b-card
        v-for="orderItem in orderSummary.order_items"
        :key="orderItem.order_item_id"
        class="ecommerce-card"
        no-body
      >

        <!-- Product Image -->
        <div class="item-img p-1">
          <b-img
            :src="imageForItem(orderItem)"
            :alt="`${orderItem.order_item_name}`"
          />
        </div>

        <!-- Product Details: Card Body -->
        <b-card-body class="d-flex justify-content-center">
          <div class="item-name">
            <h6 class="mb-0">
              <span class="text-body">
                {{ orderItem.order_item_name }}
              </span>
            </h6>
          </div>
          <div class="item-quantity">
            <span class="quantity-title">Qty:</span>
            <b-form-spinbutton
              :value="orderItem.order_item_quantity"
              size="sm"
              class="ml-75"
              inline
              min="1"
              max="9999"
              @change="handleQuantityChange($event, orderItem)"
            />
          </div>
        </b-card-body>

        <!-- Product Options/Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">
                ${{ orderItem.order_item_subtotal }}
              </h4>
            </div>
          </div>
          <b-button
            variant="light"
            class="mt-1 remove-wishlist"
            @click="confirmRemoval(orderItem)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-50"
            />
            <span>Remove</span>
          </b-button>
        </div>
      </b-card>
    </div>
    <!-- Checkout Options -->
    <div class="checkout-options w-100">
      <b-card>
        <div class="price-details">
          <h6 class="price-title">
            Price Details
          </h6>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title">
                Subtotal
              </div>
              <div class="detail-amt">
                ${{ orderSummary.order_subtotal }}
              </div>
            </li>
            <li
              v-for="(fee, index) in orderSummary.order_fees"
              :key="`fee${index}`"
              class="price-detail"
            >
              <div class="detail-title">
                {{ fee.fee.fee_name }}
              </div>
              <div class="detail-amt">
                ${{ fee.order_fee_amount }}
              </div>
            </li>
            <li
              v-for="(discount, index) in orderSummary.order_discounts"
              :key="`discount${index}`"
              class="price-detail"
            >
              <div class="detail-title">
                {{ discount.discount.discount_name }}
              </div>
              <div class="detail-amt">
                -${{ discount.order_discount_amount }}
              </div>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                Estimated Tax
              </div>
              <div class="detail-amt">
                ${{ orderSummary.order_tax }}
              </div>
            </li>
          </ul>
          <hr>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title detail-total">
                Total
              </div>
              <div class="detail-amt font-weight-bolder">
                ${{ orderSummary.order_total }}
              </div>
            </li>
          </ul>
          <b-button
            variant="primary"
            block
            @click="$emit('next-step')"
          >
            Review Order
          </b-button>
          <spinner-button
            class="mt-1"
            variant="outline-primary"
            :loading="saving"
            block
            @click="saveForLater"
          >
            Save For Later
          </spinner-button>
        </div>

      </b-card>
    </div>
    <icon-modal
      ref="summaryConfirm"
      icon="XOctagonIcon"
      icon-classes="text-danger"
      @close="temp = null"
    >
      <p class="text-center">
        Are you sure you would like to remove this item from your order?
      </p>
      <template v-slot:footer>
        <b-button @click="$refs.summaryConfirm.close()">
          Go Back
        </b-button>
        <spinner-button
          variant="danger"
          :loading="removing"
          @click="removeItem"
        >
          Remove
        </spinner-button>
      </template>
    </icon-modal>
  </div>
</template>

<script>
import {
  BButton, BCard, BFormSpinbutton, BCardBody, BImg,
} from 'bootstrap-vue'
import store from '@/store'
import orderStoreService from '@/services/orderStoreService'
import { onUnmounted, ref } from '@vue/composition-api/dist/vue-composition-api'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'
import { $themeConfig } from '@themeConfig'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import IconModal from '@/layouts/components/IconModal.vue'

export default {
  components: {
    SpinnerButton,
    // BSV
    BButton,
    BCard,
    BFormSpinbutton,
    BCardBody,
    BImg,

    IconModal,
  },
  props: {
  },
  setup() {
    const ORDER_STORE_MODULE_NAME = 'app-order'
    if (!store.hasModule(ORDER_STORE_MODULE_NAME)) store.registerModule(ORDER_STORE_MODULE_NAME, orderStoreService)

    onUnmounted(() => {
      if (store.hasModule(ORDER_STORE_MODULE_NAME)) store.unregisterModule(ORDER_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const newOrder = {
      order_items: [],
      order_subtotal: 0,
      order_total: 0,
      order_taxes: 0,
    }

    const orderSummary = ref(JSON.parse(JSON.stringify(newOrder)))

    const orderId = router.currentRoute.params.order_id
    const temp = ref(null)
    const summaryConfirm = ref(null)

    const removing = ref(false)
    const saving = ref(false)

    const refresh = () => {
      store.dispatch('app-order/fetchOrderSummary', { orderId })
        .then(response => {
          store.commit('app-order/updateOrderSummary', response.data)
          orderSummary.value = store.getters['app-order/orderSummary']
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const saveForLater = () => {
      saving.value = true
      store.dispatch('app-order/saveOrderForLater', { orderId })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully saved this order for later',
              variant: 'success',
              icon: 'CheckSquareIcon',
            },
          })

          router.push({ name: 'orders' })
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong trying to save this order please try again later',
              variant: 'danger',
              icon: 'AlertTriangleIcon',
            },
          })
        }).finally(() => {
          saving.value = false
        })
    }

    const imageForItem = item => ('order_item_group_image_url' in item ? item.order_item_group_image_url : item.order_item_image_url) ?? $themeConfig.app.appLogoWithName

    const timer = ref(null)

    const handleQuantityChange = (quantity, item) => {
      clearTimeout(timer.value)

      timer.value = setTimeout(() => {
        const action = 'item_id' in item ? 'app-order/updateItemInOrder' : 'app-order/updateItemGroupInOrder'
        const id = 'item_id' in item ? item.item_id : item.item_group_id
        store.dispatch(action, { orderId, id, quantity })
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Successfully updated the item in your order',
                variant: 'success',
                icon: 'CheckSquareIcon',
              },
            })
          }).catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong trying to update this items quantity please try again later',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            refresh()
          })
      }, 500)
    }

    const confirmRemoval = orderItem => {
      temp.value = orderItem
      summaryConfirm.value.open()
    }

    const removeItem = () => {
      removing.value = true
      const action = 'item_id' in temp.value ? 'app-order/removeItemFromOrder' : 'app-order/removeItemGroupFromOrder'
      const id = 'item_id' in temp.value ? temp.value.order_item_id : temp.value.order_item_group_id
      const payload = {
        orderId,
        id,
      }
      store.dispatch(action, payload)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully removed the item from your order',
              variant: 'success',
              icon: 'CheckSquareIcon',
            },
          })
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong when removing this item from your order please try again later',
              variant: 'danger',
              icon: 'AlertTriangleIcon',
            },
          })
        }).finally(() => {
          refresh()
          removing.value = false
          summaryConfirm.value.close()
        })
    }

    return {
      orderSummary,
      imageForItem,

      saving,
      saveForLater,

      refresh,
      handleQuantityChange,
      confirmRemoval,
      removeItem,
      temp,
      summaryConfirm,
    }
  },
}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
</style>
