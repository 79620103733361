import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import itemStoreService from '@/services/itemStoreService'

export const useShopFiltersSortingAndPagination = () => {
  const filters = ref({
    q: '',
    page: 1,
    perPage: 10,
    categories: [],
  })

  return {
    // Filter
    filters,
  }
}

export const useShopRemoteData = () => {
  // Store setup
  const ITEM_STORE_MODULE_NAME = 'app-item'
  if (!store.hasModule(ITEM_STORE_MODULE_NAME)) store.registerModule(ITEM_STORE_MODULE_NAME, itemStoreService)

  onUnmounted(() => {
    if (store.hasModule(ITEM_STORE_MODULE_NAME)) store.unregisterModule(ITEM_STORE_MODULE_NAME)
  })
  const items = ref([])
  const fetchItems = (...args) => store.dispatch('app-item/fetchOrderOptions', ...args)

  return {
    items,
    fetchItems,
  }
}
