<template>
  <b-card no-body>
    <b-card-header class="flex-column align-items-start">
      <b-card-title>
        Review
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <validation-observer
        ref="refFormObserver"
      >
        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <!--    Location        -->
            <b-form-group label="Location">
              <location-picker
                :value="orderSummary.order_location.location_id"
                :disabled="updatingLocation"
                :emit-on-load="false"
                @input="updateLocationId"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="6"
          >
            <validation-provider
              #default="validationContext"
              name="delivery type"
              rules="required"
            >
              <!--  Delivery Type  -->
              <b-form-group label="Delivery Type">
                <v-select
                  v-model="scheduleDetails.type"
                  :options="orderTypes"
                  label="label"
                  :reduce="type => type.type"
                  class="form-control p-0 border-0"
                  :class="getValidationStateNonBootstrap(validationContext)"
                  :clearable="false"
                  :disabled="!canReceiveDeliveries || updatingLocation"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <template v-if="scheduleDetails.type === 'delivery'">
              <b-row>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="street 1"
                    rules="required"
                  >
                    <b-form-group label="Street 1">
                      <b-form-input
                        v-model="scheduleDetails.deliveryAddress.street1"
                        :state="getValidationState(validationContext)"
                        :disabled="updatingLocation"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="12"
                  lg="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="street 2"
                    rules=""
                  >
                    <b-form-group label="Street 2">
                      <b-form-input
                        v-model="scheduleDetails.deliveryAddress.street2"
                        :state="getValidationState(validationContext)"
                        :disabled="updatingLocation"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="city"
                    rules="required"
                  >
                    <b-form-group label="City">
                      <b-form-input
                        v-model="scheduleDetails.deliveryAddress.city"
                        :state="getValidationState(validationContext)"
                        :disabled="updatingLocation"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="State">
                    <b-form-input
                      value="Florida"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  lg="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="zip code"
                    rules="required|length:5"
                  >
                    <b-form-group label="Zip Code">
                      <b-form-input
                        v-model="scheduleDetails.deliveryAddress.zipCode"
                        type="number"
                        :state="getValidationState(validationContext)"
                        :disabled="updatingLocation"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </template>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <validation-provider
              #default="validationContext"
              name="order date"
              rules="required"
            >
              <b-form-group label="Order Date">
                <b-form-datepicker
                  v-model="scheduleDetails.date"
                  :date-disabled-fn="disabledDate"
                  :state="getValidationState(validationContext)"
                  :disabled="updatingLocation"
                  @input="fetchAvailability"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <validation-provider
              #default="validationContext"
              name="order time"
              :rules="{required: true, date_between:[minTime, maxTime]}"
            >
              <b-form-group label="Order Time">
                <b-form-timepicker
                  v-model="scheduleDetails.time"
                  :state="getValidationState(validationContext)"
                  :disabled="!hasAvailability || updatingLocation"
                  hide-header
                  minutes-step="5"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>

      <div class="float-right">
        <spinner-button
          variant="primary"
          :loading="scheduling"
          @click="submit"
        >
          Submit
        </spinner-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormDatepicker,
  BFormTimepicker,
  BFormGroup,
  BFormInvalidFeedback,
  BFormInput,
  BRow,
  BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import orderStoreService from '@/services/orderStoreService'
import {
  onUnmounted, ref, nextTick, computed,
} from '@vue/composition-api/dist/vue-composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import moment from 'moment'
import router from '@/router'
import LocationPicker from '@/layouts/components/LocationPicker.vue'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormDatepicker,
    BFormTimepicker,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    BRow,
    BCol,
    LocationPicker,
    SpinnerButton,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const ORDER_STORE_MODULE_NAME = 'app-order'
    if (!store.hasModule(ORDER_STORE_MODULE_NAME)) store.registerModule(ORDER_STORE_MODULE_NAME, orderStoreService)

    onUnmounted(() => {
      if (store.hasModule(ORDER_STORE_MODULE_NAME)) store.unregisterModule(ORDER_STORE_MODULE_NAME)
    })

    const newScheduleDetails = {
      type: 'pickup',
      date: null,
      time: null,
      deliveryAddress: {
        street1: null,
        street2: null,
        city: null,
        zipCode: null,
      },
    }

    const orderTypes = [
      {
        type: 'pickup',
        label: 'Pick Up',
      },
      {
        type: 'delivery',
        label: 'Delivery',
      },
    ]

    const scheduleDetails = ref(JSON.parse(JSON.stringify(newScheduleDetails)))
    const orderSummary = computed(() => store.getters['app-order/orderSummary'])
    const minDate = ref(null)
    const maxDate = ref(null)
    const minTime = ref(null)
    const maxTime = ref(null)
    const orderId = router.currentRoute.params.order_id
    const scheduling = ref(false)
    const { canReceiveDeliveries } = store.getters['session/user']
    const updatingLocation = ref(false)

    const toast = useToast()

    const { refFormObserver, getValidationState, getValidationStateNonBootstrap } = formValidation()

    const hasAvailability = ref(false)

    const disabledDate = (ymd, date) => {
      let minCutoff = new Date()
      if (minDate.value != null) {
        minCutoff = minDate.value.toDate()
      }

      let maxCutoff = new Date()
      if (maxDate.value != null) {
        maxCutoff = maxDate.value.toDate()
      }

      return date.getTime() < minCutoff.getTime() || date.getTime() > maxCutoff.getTime()
    }

    const refresh = () => {
      hasAvailability.value = false
      if (orderSummary.value?.order_location?.location_id != null) {
        store.dispatch('app-location/fetchMinMaxDates', { locationId: orderSummary.value?.order_location?.location_id })
          .then(response => {
            minDate.value = moment(response.data.minimum_date, 'YYYY-MM-DD')
            maxDate.value = moment(response.data.maximum_date, 'YYYY-MM-DD')
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong receiving the minimum and maximum order dates please try again later',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    const updateLocationId = locationId => {
      orderSummary.value.order_location.location_id = locationId
      updatingLocation.value = true
      store.dispatch('app-order/updateOrderLocation', { orderId, locationId })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully updated your location',
              variant: 'success',
              icon: 'CheckSquareIcon',
            },
          })

          scheduleDetails.value = JSON.parse(JSON.stringify(newScheduleDetails))
          minDate.value = null
          minTime.value = null
          maxDate.value = null
          maxTime.value = null

          refresh()
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong trying to update your location please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          updatingLocation.value = false
        })
    }

    const fetchAvailability = () => {
      nextTick(() => {
        const formattedDate = moment(scheduleDetails.value.date, 'YYYY-MM-DD').format('MM-DD-YYYY')
        store.dispatch('app-location/fetchDateAvailability', { locationId: orderSummary.value?.order_location?.location_id, date: formattedDate })
          .then(response => {
            if (response.data.availability) {
              hasAvailability.value = true
              minTime.value = moment(response.data.start)
              maxTime.value = moment(response.data.end)
            } else {
              hasAvailability.value = false
              toast({
                component: ToastificationContent,
                props: {
                  title: 'There is no availability for this day please select another one and try again',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          }).catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong getting availability please try again later',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      })
    }

    const submit = () => {
      const scheduleOrder = () => {
        const datetime = moment(`${scheduleDetails.value.date} ${scheduleDetails.value.time}`).toISOString().replace('Z', '000Z')

        const submissionDetails = {
          order_type: scheduleDetails.value.type,
          datetime,
        }

        if (submissionDetails.order_type === 'delivery') {
          submissionDetails.delivery_address = {
            street_1: scheduleDetails.value.deliveryAddress.street1,
            street_2: scheduleDetails.value.deliveryAddress.street2,
            city: scheduleDetails.value.deliveryAddress.city,
            zip_code: scheduleDetails.value.deliveryAddress.zipCode,
          }
        }

        scheduling.value = true
        store.dispatch('app-order/scheduleOrder', { orderId, submissionDetails })
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Successfully scheduled your order',
                icon: 'CheckSquareIcon',
                variant: 'success',
              },
            })
            router.push({ name: 'orders' })
          }).catch(error => {
            let message = 'Something went wrong scheduling your order please try again later'

            if (error.response && error.response.data && error.response.data.error) {
              message = error.response.data.error
            }
            toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            scheduling.value = false
          })
      }

      refFormObserver.value.handleSubmit(scheduleOrder)
    }

    return {
      scheduleDetails,
      refFormObserver,
      orderTypes,
      canReceiveDeliveries,
      hasAvailability,
      minTime,
      maxTime,
      orderSummary,
      updatingLocation,
      scheduling,

      getValidationState,
      getValidationStateNonBootstrap,
      disabledDate,
      updateLocationId,
      refresh,
      fetchAvailability,
      submit,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
